import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from './cache-keys';
import { GetPermissionsQuery, PermissionsResponse } from 'src/API';
import { API, graphqlOperation } from 'aws-amplify';
import { getPermissions } from 'src/graphql/queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';

async function apiGetPermissions(): Promise<PermissionsResponse> {
  const response = (await API.graphql(
    graphqlOperation(getPermissions)
  )) as GraphQLResult<GetPermissionsQuery>;

  if (response.data?.getPermissions?.status !== 200) {
    throw new Error(
      `Failed getPermissions with status: ${response.data?.getPermissions?.status}`
    );
  }

  return response.data.getPermissions;
}

export interface UsePermissionsResponse {
  error: Error | null;
  isError: boolean;
  isFetching: boolean;
  isLoading: boolean;
  permissions: PermissionsResponse | undefined;
}

export const usePermissions = (): UsePermissionsResponse => {
  const {
    isLoading,
    isFetching,
    data: permissions,
    isError,
    error,
  } = useQuery({
    queryKey: [CacheKeys.Permissions],
    queryFn: async (): Promise<PermissionsResponse> => {
      try {
        return await apiGetPermissions();
      } catch (err) {
        throw new Error('Unable to retrieve user permissions');
      }
    },
  });

  return {
    isLoading,
    isFetching,
    permissions,
    isError,
    error,
  };
};
