import { Resource, ResourceAction } from '@components/permissions/const';
import React, { PropsWithChildren } from 'react';
import { useHasResourcePermission } from './useHasResourcePermission';

interface Props {
  action: ResourceAction;
  inverse?: boolean;
  resourceType: Resource;
}

export function PermissionGuard({
  resourceType,
  action,
  inverse,
  children,
}: PropsWithChildren<Props>) {
  const { isLoading, hasPermission } = useHasResourcePermission(
    resourceType,
    action
  );

  if (isLoading) {
    return <></>;
  }
  if ((inverse && hasPermission) || (!inverse && !hasPermission)) {
    return <></>;
  }

  return <>{children}</>;
}
