import { Resource, ResourceAction } from '@components/permissions/const';
import { useMemo } from 'react';
import { usePermissions } from 'src/services/usePermissions';

export function useHasResourcePermission(
  resourceType: Resource,
  action: ResourceAction
) {
  const { error, isError, isFetching, isLoading, permissions } =
    usePermissions();
  const hasPermission = useMemo(
    () =>
      Boolean(
        permissions?.permissions?.find(
          permission =>
            permission?.resource === resourceType &&
            permission.actions.find(
              actionDefinition => actionDefinition?.action === action
            )
        )
      ),
    [permissions?.permissions, resourceType, action]
  );

  return { error, isError, isFetching, isLoading, hasPermission };
}
