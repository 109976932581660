export enum Resource {
  Device = 'Device',
  DeviceLink = 'DeviceLink',
  Site = 'Site',
}

export enum ResourceAction {
  Read = 'Read',
  Write = 'Write',
}
