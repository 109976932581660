export interface EnvironmentVariables {
  readonly appsyncEndpoint: string;
  readonly awsAccount: string;
  readonly awsRegion: string;
  readonly cloudFrontDomain: string;
  readonly cloudFrontProtocol: string;
  readonly identifyPoolDomain: string;
  readonly identityPoolId: string;
  readonly stage: string;
  readonly userPoolClientId: string;
  readonly userPoolId: string;
}

export const envVariables: EnvironmentVariables = {
  awsAccount: '803225719980',
  identityPoolId: 'us-east-1:2f904709-29a1-4c09-8415-4e6c38566ab5',
  userPoolClientId: '3dc040si82e4r96as295smbief',
  userPoolId: 'us-east-1_UpNgJ9PDn',
  identifyPoolDomain: 'acs-silencio-prod.auth.us-east-1.amazoncognito.com',
  awsRegion: 'us-east-1',
  stage: 'prod',
  cloudFrontProtocol: 'https',
  cloudFrontDomain: 'd217llmxzil1xb.cloudfront.net',
  appsyncEndpoint: 'api.device-rename.acs.amazon.dev',
};
