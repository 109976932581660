import {
  Alert,
  Box,
  Container,
  Grid,
  Header,
} from '@amzn/awsui-components-react';
import { Resource, ResourceAction } from '@components/permissions/const';
import { PermissionGuard } from '@components/permissions/PermissionGuard';
import { getDevicesForSites } from '@features/isc/actions';
import { DeviceMenu } from '@features/isc/DeviceMenu';
import { DeviceTree } from '@features/isc/DeviceTree';
import TopNotification from '@features/isc/TopNotification';
import UserPreferences from '@features/isc/UserPreferences';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HowManyPixel } from 'src/components/HowManyPixel';
import DevPanel from 'src/pages/isc/layout/DevPanel';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { selectSelectedSiteCodes } from 'src/stores/slices/userPrefsSlice';

export default function MainContent(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedSitesFromState = useAppSelector(selectSelectedSiteCodes);

  useEffect(() => {
    if (selectedSitesFromState?.length) {
      dispatch(getDevicesForSites(selectedSitesFromState));
    }
    return;
  }, [dispatch, selectedSitesFromState]);

  return (
    <>
      <Box>
        <TopNotification />
        <PermissionGuard
          action={ResourceAction.Write}
          resourceType={Resource.Device}
          inverse>
          <Grid gridDefinition={[{ colspan: 10, offset: { xxs: 1 } }]}>
            <Alert statusIconAriaLabel="Warning" type="warning" dismissible>
              Device renaming is not available due to lack of Device Write
              permissions
            </Alert>
          </Grid>
        </PermissionGuard>
        <Grid
          gridDefinition={[{ colspan: 2, offset: { xxs: 1 } }, { colspan: 8 }]}>
          <Container header={<Header variant="h2">{t('Actions')}</Header>}>
            <DeviceMenu />
          </Container>
          <Container header={<Header variant="h2">{t('Devices')}</Header>}>
            <DeviceTree key={'tree'} />
          </Container>
        </Grid>
      </Box>
      <HowManyPixel />
      <UserPreferences />
      <DevPanel />
    </>
  );
}
